import React, { useState, useEffect } from "react";
import ComicList from "./ComicList";
import { getUrl, extractRows, extractDate  } from "../../Utils/google";

const url = getUrl('schedule', '1c88wglS8U92ahLpOMBf6GZgoO8LzOJOAm75cPiI7xH8');
const query = `${url}`;

const date = new Date();

export default function Archive() {
    const [googleData, setGoogleData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        const fullQuery = `${query}SELECT * WHERE B <= date '${year}-${month}-${day}'`;
        fetch(fullQuery)
            .then(res => res.text())
            .then(rep => {
                const rows = extractRows(rep)
                const data = rows.map((row, index) => {
                    return {
                        id: index,
                        title: row[0],
                        date: extractDate(row[1]),
                        alt: row[2],
                        note: row[3],
                        src: row[4]
                    }
                });
                setGoogleData(data);
            })
            .catch(err => {
                console.log(err);
                setError('Unknown error occured');
            })
    }

    return (<>
        <ComicList comics={googleData} />
    </>)
}