import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import logo from '../../../Assets/icontrans.png'
import { BLUE_STEEL, I_CANT_BELIEVE_ITS_NOT_BLACK, POCKET_FULL_OF_SUNSHINE, SUNBLEACHED_LEGO_BLUE } from "../../../Constants/colors";
import { SITE_WIDTH } from "../../../Constants/sizes";

const Container = styled.div`
    background-color: ${SUNBLEACHED_LEGO_BLUE};
    border-bottom: 10px solid ${BLUE_STEEL};
    box-shadow: -10px -10px 10px 20px lightslategrey;
    padding: 15px;
`

const Boundry = styled.div`
    display: flex;
    max-width: ${SITE_WIDTH};
    margin: auto;
    align-items: end;
    flex-wrap: wrap;
    & > a {
        display: flex;
        align-items: end;
    }
`

const Logo = styled.img`
    object-fit: contain;
    height: 3.5rem;
    margin-right: 10px;
`

const Title = styled.h1`
    margin: 0;
    font-size: 3rem;
    font-weight: bold;
    color: ${I_CANT_BELIEVE_ITS_NOT_BLACK};
`

const Subtitle = styled.p`
    margin: 0 10px;
    font-size: 1.5rem;
    margin-top: 10px;
`

const List = styled.div`
    flex: auto;
    margin-top: 10px;
    display: flex;
    justify-content: end;
    & > a {
        margin-right: 30px;
        font-size: 1.5rem;
        font-weight: bold;
        color: ${I_CANT_BELIEVE_ITS_NOT_BLACK};
        text-decoration: underline;
        &:hover {
            text-shadow: 1px 1px 3px ${POCKET_FULL_OF_SUNSHINE};
        }
    }
`


export default function Navbar({ links, excludedPaths = []}) {

    let path = useLocation();
    const hide = excludedPaths.some(p => path.pathname.includes(p));

    const NavLinks = links ? links.map(link => (
        <Link to={`/${link.path}`} key={link.path}>{link.text}</Link>
    )) : null

    return (
        <Container style={hide ? { display: 'none' } : {}}>
            <Boundry>
                <Link to="/">
                    <Logo src={logo} alt="Tale-Top - Home" />
                    <Title>Tale-Top</Title>
                </Link>
                <Subtitle>Art & Adventure</Subtitle>
                <List>{NavLinks}</List>
            </Boundry>
        </Container>)
}