import React, { useEffect, useState, useRef } from "react";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";

import { BLUE_STEEL, DICK_GREYSON, POCKET_FULL_OF_SUNSHINE } from "../../Constants/colors";

const Image = styled.img`
    max-width: 140px;
    min-width: 140px;
    border: 1px solid ${BLUE_STEEL};
    border-radius: 5px;
    margin: 5px;
    min-height: 140px;
    &:hover {
        border-color: ${POCKET_FULL_OF_SUNSHINE};
    }
`

const NewLabel = styled.span`
    font-weight: bold;
    font-size: 1em;
    color: red;
    position: absolute;
    right: 15px;
    bottom: 15px;
    text-shadow: 1px 1px 5px white, -1px -1px 5px white, 1px -1px 5px white, -1px 1px 5px white
`

const Link = styled.a`
    position: relative;
`

const Loading = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export default function GalleryImage({ isNew, src, ...rest }) {
    const [loading, setLoading] = useState(true)
    const imageTag = useRef(null)

    useEffect(() => {
        if (imageTag && imageTag.current.complete) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [src])

    return (
        <Link href={src} target="_blank">
            {loading && <Loading><RotatingLines strokeColor={DICK_GREYSON}/></Loading>}
            <Image onLoad={() => setLoading(false)} src={src} {...rest} ref={imageTag}/>
            {isNew ? <NewLabel>NEW!</NewLabel> : null}
        </Link>
    )
}