import React from "react";
import styled from "styled-components";

import { I_CANT_BELIEVE_ITS_NOT_BLACK } from '../../Constants/colors'

const Content = styled.p`
    font-size: 1.5rem;
    color: ${I_CANT_BELIEVE_ITS_NOT_BLACK};
    text-indent: 3rem;
    margin 0 15px;
    @media (max-width: 500px) {
        font-size: 1.2rem;
    }
`

export default function Paragraph({ children, style=null }) {
    return <Content style={style}>{children}</Content>
}