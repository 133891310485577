import React, { useState, useEffect } from "react";
import { getUrl, extractRows, extractDate } from "../../Utils/google";
import { useNavigate, useSearchParams } from "react-router-dom";
import ComicControls from './ComicControls'
import Comic from "./Comic";
import ArchiveLink from "./ArchiveLink";
import SeeAlso from "./SeeAlso";

const url = getUrl('schedule', '1c88wglS8U92ahLpOMBf6GZgoO8LzOJOAm75cPiI7xH8')
const query = `${url}`
const currentParameterName = 'strip'

const date = new Date();

export default function ComicWithControls() {
    const [googleData, setGoogleData] = useState([]);
    const [error, setError] = useState(null);
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();

    const param = params.get(currentParameterName);
    const episodeId = parseInt(param);

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        const fullQuery = `${query}SELECT * WHERE B <= date '${year}-${month}-${day}' ORDER BY B`;
        fetch(fullQuery)
            .then(res => res.text())
            .then(rep => {
                const rows = extractRows(rep)
                const data = rows.map((row, index) => {
                    return {
                        id: index,
                        title: row[0],
                        date: extractDate(row[1]),
                        alt: row[2],
                        note: row[3],
                        src: row[4],
                        related: row[5]
                    }
                });
                setGoogleData(data);
            })
            .catch(err => {
                console.log(err);
                setError('Unknown error occured');
            })
    }

    useEffect(() => {
        if (!isNaN(episodeId)) {
            if (episodeId < 0) {
                navigate(`/counterbury-tales?strip=${googleData.length - 1}`);
            }
            if (episodeId > googleData.length - 1) {
                setError('Comic does not exist!');
            } else {
                setError(null);
            }
        } else {
            navigate(`/counterbury-tales?strip=${googleData.length - 1}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param, googleData])

    const onUpdate = v => {
        navigate(`/counterbury-tales?strip=${v}`,
            {
                replace: true,
            });
    }

    const loading = !googleData || googleData.length == 0;

    let comic = null;
    if (!loading) {
        comic = googleData[episodeId];
    }

    return (<>
            <ComicControls
                current={episodeId}
                max={googleData ? googleData.length - 1 : 0}
                onUpdate={onUpdate}
                dateString={comic && comic.date} />
            <Comic comic={comic} error={error} />
            <SeeAlso comic={comic} comics={googleData} />
            <ComicControls
                current={episodeId}
                max={googleData ? googleData.length - 1 : 0}
                onUpdate={onUpdate}
                dateString={comic && comic.date} />
            <ArchiveLink />
    </>)
}