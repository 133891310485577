import React from "react";
import styled from "styled-components";
import Markdown from "react-markdown";

const ComicWrapper = styled.div`

`

const Image = styled.img`
    width: 100%;
    margin: auto;
    display: block;
    min-height: 37.9%;
`

const Note = styled.div`
    text-align: center;
    a {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
`

const defaultComic =
{
    id: 0,
    alt: 'loading...',
    title: 'Comic',
    src: ''
}

export default function Comic({ comic = defaultComic, error = null }) {

    const hideComic = !comic || error;

    let message = 'loading...';
    if (error && comic) {
        message = error;
    }

    return (
        <ComicWrapper>
            <Image
                src={comic && comic.src}
                alt={comic && comic.alt}
                title={comic && comic.title}
                on
                style={hideComic ? { display: 'none' } : {}}
            ></Image>
            {(comic && comic.note) && <Note><Markdown>{comic.note}</Markdown></Note>}
        </ComicWrapper>
    )
}