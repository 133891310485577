import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: 10px 0;
`

export default function GalleryContainer({ children }) {
    return <Container>{children}</Container>
}