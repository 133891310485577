import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { extractRows, getUrl } from "../../Utils/google";
import SupplementGroup from "./SupplementGroup";
import SupplementCategory from "./SupplementCategory";
import TableLoading from "../Shared/TableLoading";

const Games = styled.ul`
    margin: 50px 20px 0;
    display: flex;
    flex-wrap: wrap;
`

const Table = styled.div`
    margin: 20px;
`

const url = getUrl('supplement-data')
const query = `${url}SELECT *`


export default function SupplementTable() {
    const [supplements, setSupplements] = useState([])
    const [game, setGame] = useState("dnd")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game])

    const fetchData = () => {
        setLoading(true)
        fetch(`${query} WHERE C = '${game}' ORDER BY C DESC, F DESC, D, E`)
            .then(res => res.text())
            .then(rep => {
                const rows = extractRows(rep)
                setSupplements(rows.map(row => {
                    return {
                        id: row[0] ? row[0].split('/')[5] : null,
                        name: row[1],
                        game: row[2] ? row[2].toLowerCase() : null,
                        type: row[3] ? row[3].toLowerCase() : null,
                        subType: row[4] ? row[4].toLowerCase() : null,
                        new: !!row[5],
                        printId: row[6] ? row[6].split('/')[5] : null,
                    }
                }))
                setLoading(false)
            })
    }

    let tables
    if (loading) {
        tables = <TableLoading />
    } else {
        const groups = supplements.reduce((groups, item) => ({
            ...groups,
            [item.type]: [...(groups[item.type] || []), item]
        }), {})
    
        tables = Object.keys(groups).map(key => <SupplementGroup name={key} supplements={groups[key]} key={key} />)
    }


    return (
        <>
            <Games>
                <SupplementCategory value="dnd" current={game} onChange={setGame}>DnD</SupplementCategory>
                <SupplementCategory value="bitd" current={game} onChange={setGame}>Blades in the Dark</SupplementCategory>
            </Games>
            <Table>
                {tables}
            </Table>
        </>
    )
}