import React from "react";
import styled from "styled-components";
import { I_CANT_BELIEVE_ITS_NOT_BLACK, MAGENTA_IS_THE_TEAM_COLOR } from "../../Constants/colors";

const Item = styled.li`
    color: ${MAGENTA_IS_THE_TEAM_COLOR};
    font-weight: bold;
    font-size: 1.5rem;
    padding-right: 20px;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    @media (max-width: 500px) {
        font-size: 1.2rem;
    }
`

const Selected = styled.li`
    color: ${I_CANT_BELIEVE_ITS_NOT_BLACK};
    font-weight: bold;
    font-size: 1.5rem;
    padding-right: 20px;
    @media (max-width: 500px) {
        font-size: 1.2rem;
    }
`

export default function SupplementCategory({ value, children, current, onChange }) {

    const Component = value === current ? Selected : Item;

    return (
        <Component onClick={() => onChange(value)}>
            {children}
        </Component>
    )
}