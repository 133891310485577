import React from "react";
import styled from "styled-components";
import { BLUE_STEEL } from "../../../Constants/colors";

const Field = styled.input`
    border: 1px solid ${BLUE_STEEL};
    border-radius: 4px;
    font-size: 1em;
    padding: 8px 8px;
    &:hover {
        border-color: hsl(0, 0%, 70%);
    }
    width: 100%;
    box-sizing: border-box;
`

const Container = styled.div`
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    min-width: 250px;
    @media (max-width: 500px) {
        width: 100%;
    }
`

const Label = styled.label`
    font-weight: bold;
    font-size: 1rem;
`

export default function Input({ value, onChange, placeholder="", id, label, onKeyPress }) {
    return (
        <Container>
            <Label htmlFor={id}>{label}</Label>
            <Field type="text" onChange={e => onChange(e.target.value)} value={value} placeholder={placeholder} id={id} onKeyPress={onKeyPress}/>
        </Container>
    )
}