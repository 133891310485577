import React, { Fragment } from "react";
import styled from "styled-components";

const LinkSection = styled.p`
    font-size: 0.8rem;
    text-align: center;
    padding: 5px;
`

const Link = styled.a`
    margin-left: 5px;
`

const today = new Date();

export default function SeeAlso({ comic, comics }) {

    if (!comic || !comic.related || comic.related.length <= 0 || !comics || comics.length <= 0) {
        return null;
    }

    const noBracks = comic.related.replace(/[{}]/g, '');
    const relatedList = noBracks.split(',');

    const items = relatedList.map((n, index) => {
        const id = parseInt(n);
        const com = comics.find(c => c.id == id && Date.parse(c.date) <= today)
        if (com) {
            return (
                <Fragment key={com.id}>
                    <Link href={`/counterbury-tales?strip=${id}`}>{id + 1}: {com.title}</Link>
                    {index < relatedList.length - 1 && ','}
                </Fragment>
            )
        } else {
            return null;
        }

    });

    if (items.every(c => c == null)) {
        return null;
    }

    return (
        <>
            <LinkSection>
                See Also: {items}
            </LinkSection>
        </>)
}