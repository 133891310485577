import React from "react";
import ComicWrapper from "../Components/Comics/ComicWrapper";
import ComicWithControls from "../Components/Comics/ComicWithControls";
import Archive from "../Components/Comics/Archive";

export default function CounterburyArchive() {

    return (
        <>
            <ComicWrapper>
                <Archive />
            </ComicWrapper>
        </>
    )
}