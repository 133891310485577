import React from "react";
import styled from "styled-components";
import Caption from "./Caption";

import { BLUE_STEEL } from "../../Constants/colors";

const Image = styled.img`
    border: ${props => props.border ? `10px solid ${BLUE_STEEL}` : "none"};
    max-width: ${props => props.width};
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    box-sizing: border-box;
    @media (max-width: 800px) {
        max-width: 100%;
        width: 100%;
    }
`

const Container = styled.div`
    max-width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: ${props => props.flexDirection};
    justify-content: center;
    align-items: center;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`

export default function CaptionedImage({ image, alt, caption, border=true, captionPostition="under" }) {

    let width = "100%"
    let flexDirection = "column"

    switch (captionPostition) {
        case "under":
            break;
        case "left":
            width = "50%"
            flexDirection = "row-reverse"
            break;
        case "right":
            width = "50%"
            flexDirection = "row"
            break;
        default:
            throw new Error(`Unrecognized captionPosition "${captionPostition}"`)
    }

    return (
        <Container flexDirection={flexDirection}>
            <Image src={image} alt={alt} border={border} width={width}/>
            <Caption>{caption}</Caption>
        </Container>
    )
}