import React from "react";
import styled from "styled-components";
import A from "../Shared/A";

import { SUNBLEACHED_LEGO_BLUE } from "../../Constants/colors";

const TR = styled.tr`
    &:nth-child(even) {
        background-color: ${SUNBLEACHED_LEGO_BLUE}55;
    }
`

const TD = styled.td`
    font-size: 1rem;
    padding: 10px;
    white-space: nowrap;
`

const Name = styled.td`
    font-size: 1rem;
    padding: 10px;
    width: 100%;
`

const New = styled.span`
    color: red;
`

export default function Supplement({ supplement }) {
    return (
        <TR>

            <Name>{supplement.new && <New>New! </New>}{supplement.name}</Name>
            <TD><A src={`https://drive.google.com/file/d/${supplement.id}/view`}>View</A></TD>
            <TD><A src={`https://drive.google.com/u/0/uc?id=${supplement.id}&export=download`} download>Download</A></TD>
            <TD>{supplement.printId && <A src={`https://drive.google.com/file/d/${supplement.printId}/view`}>Print Friendly</A>}</TD>
        </TR>
    )
}