import React from "react";
import styled from "styled-components";
import { Helmet } from 'react-helmet';
import PageTitle from "../Components/Shared/PageTitle";
import Paragraph from "../Components/Shared/Paragraph";
import A from "../Components/Shared/A";
import SubHeading from "../Components/Shared/SubHeading";

import portrait from "../Assets/SSPortrait.png"
import { BLUE_STEEL } from "../Constants/colors";
import { TWITTER, INSTAGRAM, KOFI, ROLL_20, EMAIL, LINKEDIN } from "../Constants/externalUrls";

const Bio = styled.div`
    display: flex;
    margin: 20px 0;
    flex-direction: row;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`

const BioText = styled.div`

`

const Name = styled.h3`
    padding: 20px;
    font-size: 2rem;
`

const Image = styled.img`
    border: 10px solid ${BLUE_STEEL};
    box-sizing: border-box;
    max-width: 300px;
    height: fit-content;
    @media (max-width: 900px) {
        max-width: 100%;
    }
`

const Socials = styled.ul`
    font-size: 1.5rem;
    padding: 0 20px 30px;;
    & li {
        padding: 5px 0;
    }
`


export default function About() {
    return (
        <>
            <Helmet>
                <title>Tale-Top - About</title>
            </Helmet>
            <PageTitle>
                About Tale-Top
            </PageTitle>
            <Bio>
                <Image src={portrait} alt="Self portrait in pixel art"></Image>
                <BioText>
                    <Name>
                        <strong>Scotti Anderson</strong> (He/Him)
                    </Name>
                    <Paragraph>
                        Software developer, pixel artist, Roll20 marketplace creator, TTRPG hacker, etc. I built this site as a place to publish and share my creative projects. I'm self-taught in everything but software (paid a lot of money to learn how to do that), but I enjoy learning by doing. If you like my creative work, consider following me or supporting me on Ko-Fi!
                    </Paragraph>
                </BioText>
            </Bio>
            <SubHeading>
                Find me:
            </SubHeading>
            <Socials>
                <li>Follow me on Twitter: <A src={TWITTER} >@taletopgames</A></li>
                <li>Follow me on Instagram: <A src={INSTAGRAM}>@picture.elemental</A></li>
                <li>Support me on Ko-Fi: <A src={KOFI}>ko-fi.com/taletop</A></li>
                <li>Find my work in the Roll20 marketplace: <A src={ROLL_20}>Tale-Top Games</A></li>
                <li>Send me an e-mail: <A src={EMAIL}>TaleTopGames@gmail.com</A></li>
                <li>Hire me: <A src={LINKEDIN}>Scotti Anderson</A></li>
            </Socials>
        </>
    )
}