import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
    flex-wrap: wrap;
    & a {
        padding: 0 15px;
        font-weight: bold;
        font-size: 1.5em;
        @media (max-width: 500px) {
            font-size: 1.2rem;
        }
    }
`

export default function CTAContainer({children}) {
    return <Container>{children}</Container>
}