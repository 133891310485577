import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UH_OH_SPAGHETIO } from "../../Constants/colors";
import { extractRows, getUrl } from "../../Utils/google";
import Button from "../Shared/Form/Button";
import Dropdown from "../Shared/Form/Dropdown";
import Input from "../Shared/Form/Input";
import TableLoading from "../Shared/TableLoading";
import GalleryContainer from "./GalleryContainer";
import GalleryImage from "./GalleryImage";
import PageNavigation from "./PageNavigation";

const FilterContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

const Placeholder = styled.div`
    width: 50%;
`

const ButtonContainer = styled.div`
    padding: 10px;
`

const NoResults = styled.div`
    width 100%;
    text-align: center;
    padding: 40px 10px;
    font-size: 1.5rem;
`

const Error = styled.div`
    width 100%;
    text-align: center;
    padding: 40px 10px;
    font-size: 1.5rem;
    color: ${UH_OH_SPAGHETIO};
`

const url = getUrl('token-data');

const TOKENS_PER_PAGE = 24

const sizeOptions = [
    { value: "tiny", label: "Tiny (< 35 x 35)" },
    { value: "small", label: "Small (< 70 x 70)" },
    { value: "medium", label: "Medium (70 x 70)" },
    { value: "large", label: "Large (140 x 140)" },
    { value: "huge", label: "Huge (280 x 280)" },
    { value: "gargantuan", label: "Gargantuan (560 x 560)" }
]

const typeOptions = [
    { value: "aberration", label: "Aberration" },
    { value: "beast", label: "Beast" },
    { value: "celestial", label: "Celestial" },
    { value: "construct", label: "Construct" },
    { value: "dragon", label: "Dragon" },
    { value: "elemental", label: "Elemental" },
    { value: "fey", label: "Fey" },
    { value: "fiend", label: "Fiend" },
    { value: "giant", label: "Giant" },
    { value: "humanoid", label: "Humanoid" },
    { value: "ooze", label: "Ooze" },
    { value: "plant", label: "Plant" },
    { value: "undead", label: "Undead" },
]

const environmentOptions = [
    { value: "arctic", label: "Arctic" },
    { value: "coastal", label: "Coastal" },
    { value: "desert", label: "Desert" },
    { value: "forest", label: "Forest" },
    { value: "grassland", label: "Grassland" },
    { value: "hill", label: "Hill" },
    { value: "jungle", label: "Jungle" },
    { value: "mountain", label: "Mountain" },
    { value: "swamp", label: "Swamp" },
    { value: "underdark", label: "Underground" },
    { value: "underwater", label: "Underwater" },
    { value: "urban", label: "Urban" },
]

const packOptions = [
    { value: "courts of the fey", label: "Courts of the Fey" },
    { value: "the undead horde", label: "The Undead Horde" },
    { value: "heroes of the realm", label: "Heroes of the Realm" },
    { value: "unreleased", label: "No Pack" },
]

const imgBase = "https://drive.google.com/uc?id=";
export default function Gallery() {

    const [tokens, setTokens] = useState([])
    const [totalNumber, setTotalNumber] = useState(0)
    const [page, setPage] = useState(0)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    const [changed, setChanged] = useState(0)

    const [search, setSearch] = useState("")
    const [sizes, setSizes] = useState([])
    const [types, setTypes] = useState([])
    const [environments, setEnvironments] = useState([])
    const [packs, setPacks] = useState([])

    useEffect(() => {
        fetchTokens()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changed])

    const fetchTokens = () => {
        const filter = buildFilter();
        setLoading(true);
        // Fetch the number of matching results
        const sizeQuery = `${url}SELECT COUNT(A) ${filter}`
        fetch(sizeQuery)
            .then(res => res.text())
            .then(rep => {
                const jsonData = JSON.parse(rep.substring(47).slice(0, -2));
                if (jsonData.table.rows[0]) {
                    setTotalNumber(jsonData.table.rows[0].c[0].v)
                }
            })

        // Fetch matching tokens for the current page
        const tokenQuery = `${url}SELECT * ${filter} ORDER BY G DESC, B LIMIT ${TOKENS_PER_PAGE} OFFSET ${page * TOKENS_PER_PAGE}`
        fetch(tokenQuery)
            .then(res => res.text())
            .then(rep => {
                const allRows = extractRows(rep)
                setTokens(
                    allRows.map(row => {
                        return {
                            imgId: row[0] ? row[0].split('/')[5] : null,
                            name: row[1] ? row[1].toLowerCase() : null,
                            size: row[2] ? row[2].toLowerCase() : null,
                            type: row[3] ? row[3].toLowerCase() : null,
                            envi: row[4] ? row[4].toLowerCase() : null,
                            pack: row[5] ? row[5].toLowerCase() : null,
                            tags: row[7] ? row[7].toLowerCase() : null,
                            new: !!row[6],
                        }
                    })
                )
                setLoading(false)
                if (error) {
                    setError(false)
                }
            })
            .catch(e => {
                setError(true)
                setLoading(false)
            })
    }

    const changePage = (newPage) => {
        setPage(newPage)
        setChanged(changed + 1)
    }

    const buildFilter = () => {
        let queryString = ""

        let firstFilter = true
        // Filter by search terms
        if (search !== null && search !== '') {
            queryString += ` WHERE ( `

            const searchTerms = search.split(/(?:,|;|:|\.|\s)+/)
            searchTerms.forEach((term, i, a) => {
                queryString += `LOWER(B) CONTAINS '${term.toLowerCase()}' 
                                OR LOWER(D) CONTAINS '${term.toLowerCase()}' 
                                OR LOWER(H) CONTAINS '${term.toLowerCase()}'`
                if (i < a.length - 1) queryString += ' OR '
            })

            queryString += ')'
            firstFilter = false
        }

        // Filter by size
        if (sizes && sizes.length > 0) {
            queryString += firstFilter ? ' WHERE ' : ' AND '
            queryString += '('

            sizes.forEach((size, i, a) => {
                queryString += `LOWER(C) = '${size.value.toLowerCase()}'`
                if (i < a.length - 1) queryString += ' OR '
            })

            queryString += ')'
            firstFilter = false
        }

        // Filter by type
        if (types && types.length > 0) {
            queryString += firstFilter ? ' WHERE ' : ' AND '
            queryString += '('

            types.forEach((type, i, a) => {
                queryString += `LOWER(D) = '${type.value.toLowerCase()}'`
                if (i < a.length - 1) queryString += ' OR '
            })

            queryString += ')'
            firstFilter = false
        }

        // Filter by environment
        if (environments && environments.length > 0) {
            queryString += firstFilter ? ' WHERE ' : ' AND '
            queryString += '('

            environments.forEach((env, i, a) => {
                queryString += `LOWER(E) CONTAINS '${env.value.toLowerCase()}'`
                if (i < a.length - 1) queryString += ' OR '
            })

            queryString += ')'
            firstFilter = false
        }

        // Filter by pack
        if (packs && packs.length > 0) {
            queryString += firstFilter ? ' WHERE ' : ' AND '
            queryString += '('

            packs.forEach((pack, i, a) => {
                if (pack.value === "unreleased") {
                    queryString += 'F IS NULL'
                } else {
                    queryString += `LOWER(F) CONTAINS '${pack.value.toLowerCase()}'`
                }
                if (i < a.length - 1) queryString += ' OR '
            })

            queryString += ')'
            firstFilter = false
        }

        return queryString
    }

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            changePage(0)
        }
    }

    let Tokens

    if (error) {
        Tokens = <Error>Something went wrong...</Error>
    } else if (loading) {
        Tokens = <TableLoading />
    } else if (tokens.length > 0) {
        Tokens = tokens.map(token => {
            return (
                <GalleryImage
                    src={`${imgBase}${token.imgId}`}
                    alt={token.name}
                    title={token.name}
                    key={token.name}
                    isNew={token.new} />
            )
        })
    } else {
        Tokens = <NoResults>No tokens found...</NoResults>
    }

    const showPages = !error && tokens.length > 0

    return (
        <GalleryContainer>
            <p style={{width: '100%', margin: '20px 0', size: '2rem', textAlign: 'center' }}>Gallery is down for maintenance :(</p>
            {/* <FilterContainer>
                <Input
                    value={search}
                    placeholder={"Search..."}
                    onChange={s => setSearch(s)}
                    onKeyPress={onKeyPress}
                    label="Search"
                    id="search-input" />
                <Placeholder />
                <Dropdown
                    items={sizeOptions}
                    isMulti
                    onChange={setSizes}
                    onKeyPress={onKeyPress}
                    value={sizes}
                    label="Size"
                    id="size-select"
                    placeholder="Any Size"
                />
                <Dropdown
                    items={typeOptions}
                    isMulti
                    onChange={setTypes}
                    onKeyPress={onKeyPress}
                    value={types}
                    label="Type"
                    id="type-select"
                    placeholder="Any Type"
                />
                <Dropdown
                    items={environmentOptions}
                    isMulti
                    onChange={setEnvironments}
                    onKeyPress={onKeyPress}
                    value={environments}
                    label="Environment"
                    id="environment-select"
                    placeholder="Any Environment"
                />
                <Dropdown
                    items={packOptions}
                    isMulti
                    onChange={setPacks}
                    onKeyPress={onKeyPress}
                    value={packs}
                    label="Roll20 Marketplace Pack"
                    id="pack-select"
                    placeholder="Any Pack"
                />
                <ButtonContainer>
                    <Button onClick={() => changePage(0)}>Apply Filter</Button>
                </ButtonContainer>
            </FilterContainer>
            {Tokens}
            {showPages &&
                <PageNavigation
                    items={totalNumber}
                    itemsPerPage={TOKENS_PER_PAGE}
                    onChange={changePage}
                />
            } */}
        </GalleryContainer>
    )

}