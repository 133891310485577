import React from "react";
import styled from "styled-components";
import ComicListItem from "./ComicListItem";

const Head = styled.h1`
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
`

const List = styled.ul`
    font-size: 1rem;
    padding: 10px;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    @media (max-width: 700px) {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        margin: 0 15%;
    }
`

export default function ComicList({ comics }) {
    
    let list = [];
    if (comics && comics.length > 0) {
        list = comics.map(c =>  <ComicListItem comic={c} key={c.id}/>);
    }

    return (<>
        <Head>Archive:</Head>
        <List>
            {list}
        </List>
    </>)
}