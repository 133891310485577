import React from "react";
import styled from "styled-components";

const Comp = styled.h3`
    font-size: 2em;
    font-weight: bold;
    padding: 10px 20px;
`

export default function SubHeading({children}) {
    return <Comp>{children}</Comp>
}