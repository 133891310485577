 export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  export function formatJsDateToNormalDate(date) {
    if(date !== null) {
          const realMonth = date.getMonth() + 1;
          let month = (realMonth < 10) ? '0' + realMonth : String(realMonth);
          let day = (date.getDate() < 10) ? '0' + date.getDate() : String(date.getDate());
          
          return [date.getFullYear(), month, day].join('-');
    } else {
      return null;
    }
  }