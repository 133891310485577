import React from "react";
import styled from "styled-components";

import splash from '../../Assets/SSPortlandSkyline.png'
import { BLUE_STEEL, SUNBLEACHED_LEGO_BLUE } from "../../Constants/colors";

const Image = styled.img`
    width: 100%;
    margin: 5% auto;
    border: 10px solid ${BLUE_STEEL};
    background-color: ${SUNBLEACHED_LEGO_BLUE};
    box-shadow: 10px 10px 10px lightslategrey;
`

export default function Splash() {
    return <Image src={splash} alt="Portland skyline in pixel art"/>
}