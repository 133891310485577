import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import twitter from '../../../Assets/twitter.png'
import insta from '../../../Assets/instagram.png'
import kofi from '../../../Assets/kofi.png'

import { BLUE_STEEL, ON_WHITE, POCKET_FULL_OF_SUNSHINE } from "../../../Constants/colors";
import { SITE_WIDTH } from "../../../Constants/sizes";
import { INSTAGRAM, KOFI, ROLL_20, TWITTER } from "../../../Constants/externalUrls"

const Container = styled.div`
    background-color: ${BLUE_STEEL};
    color: ${ON_WHITE};
`

const Boundry = styled.div`
    max-width: ${SITE_WIDTH};
    margin: auto;
    padding: 50px 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

const List = styled.ul`
    width: fit-content;
    display: flex;
    flex-direction: column;
    & li {
        padding: 5px;
    }
    & a {
        color: ${ON_WHITE};
        font-weight: bold;
        margin: 5px;
        &:hover{
            color: ${POCKET_FULL_OF_SUNSHINE};
        }
    }
`

const Icon = styled.img`
    height: 1.5rem;
    margin-bottom: -.5rem;
    margin-right: .5rem;
`

const leftLinks = [
    {
        text: "Art",
        to: "art"
    },
    {
        text: "Token Gallery",
        to: "art/tokens"
    },
    {
        text: "TTRPG",
        to: "ttrpg"
    },
    {
        text: "About Me",
        to: "about"
    },
]

const rightLinks = [
    {
        text: "@taletopgames",
        to: TWITTER,
        img: {
            src: twitter,
            alt: "Twitter"
        }
    },
    {
        text: "@picture.elemental",
        to: INSTAGRAM,
        img: {
            src: insta,
            alt: "Instagram"
        }
    },
    {
        text: "Support me on Ko-fi",
        to: KOFI,
        img: {
            src: kofi,
            alt: "Ko-Fi"
        }
    },
    {
        text: "Find me in the Roll20 marketplace",
        to: ROLL_20,
        img: null
    },
]

export default function Footer({ excludedPaths = [] }) {
    let path = useLocation();
    const hide = excludedPaths.some(p => path.pathname.includes(p));

    const LeftLinks = leftLinks.map(link => (
        <li key={link.to}><Link to={`/${link.to}`}>{link.text}</Link></li>
    ));

    const RightLinks = rightLinks.map(link => (
        <li key={link.to}>
            <a href={link.to} target="_blank" rel="noreferrer">
                {link.img && <Icon src={link.img.src} alt={link.img.alt} />}
                {link.text}
            </a>
        </li>
    ));

    return (
        <Container style={hide ? { display: 'none' } : {}}>
            <Boundry>
                <List>
                    {LeftLinks}
                </List>
                <List>
                    {RightLinks}
                </List>
            </Boundry>
        </Container>
    )
}