import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BLUE_STEEL, MAGENTA_IS_THE_TEAM_COLOR, POCKET_FULL_OF_SUNSHINE } from "../../Constants/colors";

const LinkSpan = styled.span`
    & a {
        text-decoration: underline;
        color: ${BLUE_STEEL};
        &:visited {
            color: ${MAGENTA_IS_THE_TEAM_COLOR};
        }
        &:hover {
            color: ${POCKET_FULL_OF_SUNSHINE};
        }
    }
`

export default function A({ children, src, internal=false, ...rest }) {
    const Inner = internal ? (
        <Link to={`/${src}`} {...rest}>{children}</Link>
    ) : (
        <a href={src} target="_blank" rel="noreferrer" {...rest}>{children}</a>
    );
    return (<LinkSpan>{Inner}</LinkSpan>)
}