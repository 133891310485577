import React from "react";
import styled from "styled-components";
import { ThreeDots } from "react-loader-spinner";
import { DICK_GREYSON } from "../../Constants/colors";

const Loading = styled.div`
    width: fit-content;
    margin: auto;
    padding: 40px 10px;
    font-size: 1.5rem;
`

export default function TableLoading(props) {
    return <Loading {...props}><ThreeDots color={DICK_GREYSON} /></Loading>
}