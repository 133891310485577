import React from "react";
import ReactSelect from "react-select";
import styled from "styled-components";
import { BLUE_STEEL } from "../../../Constants/colors";

const Styles = {
    control: (p) => ({
        ...p,
        borderColor: `${BLUE_STEEL}`,
    })
}

const Container = styled.div`
    width: 50%;
    padding: 0 10px 10px;
    box-sizing: border-box;
    min-width: 250px;
    @media (max-width: 500px) {
        width: 100%;
    }
`

const Label = styled.label`
    font-weight: bold;
`

export default function Dropdown({ label, id, items, isMulti = false, value, onChange, placeholder=label, onKeyPress }) {
    return (
    <Container>
        <Label htmlFor={id}>
            {label}
        </Label>
        <ReactSelect
            options={items}
            isMulti={isMulti}
            onChange={onChange}
            allowSelectAll
            value={value}
            id={id}
            placeholder={placeholder}
            styles={Styles}
            onKeyDown={onKeyPress}
        />
    </Container>
    )
}