import React from "react";
import ComicWrapper from "../Components/Comics/ComicWrapper";
import ComicWithControls from "../Components/Comics/ComicWithControls";

export default function CounterBury() {

    return (
        <>
            <ComicWrapper>
                <ComicWithControls />
            </ComicWrapper>
        </>
    )
}