import React from "react";
import styled from "styled-components";
import Caption from "./Caption";

import { BLUE_STEEL } from "../../Constants/colors";

const Container = styled.div`
    max-width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Image = styled.img`
    border: ${props => props.borders ? `5px solid ${BLUE_STEEL}` : 'none'};
    max-width: ${props => props.size};
    image-rendering: pixelated;
`

export default function CaptionedImages({images, caption, size="250px", borders=false}) {

    const Images = images.map(image => (
        <Image src={image.src} alt={image.alt} borders={borders} size={size} key={image.alt}/>
    ))

    return (
        <Container>
            <ImagesContainer>
                {Images}
            </ImagesContainer>
            <Caption>{caption}</Caption>
        </Container>
    )

}