import React from "react";
import styled from "styled-components";

const Link = styled.a`
    padding: 5px;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
`

export default function ArchiveLink() {

    return (
        <>
            <Link href='/counterbury-tales/archive'>Archive</Link>
        </>
    )
}