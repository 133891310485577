import React from "react";
import styled from "styled-components";

import { SITE_WIDTH } from "../../Constants/sizes";

const Container = styled.div`
    width: 80%;
    max-width: ${SITE_WIDTH};
    margin: auto;
    display: flex;
    flex-direction: column;
    @media (max-width: 800px) {
        width: 95%;
    }
`
export default function ContentContainer({ children }) {
    return <Container>{children}</Container>
}