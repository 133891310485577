import React from "react";
import styled from "styled-components";
import { SUNBLEACHED_LEGO_BLUE } from "../../Constants/colors";
import Supplement from "./Supplement";

const Table = styled.table`
    
`

const TH = styled.th`
    padding: 10px;
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
    font-size: 1rem;
    background-color: ${SUNBLEACHED_LEGO_BLUE};
`

export default function SupplementGroup({ name, supplements }) {
    const rows = supplements.map(sup => <Supplement supplement={sup} key={sup.id}/>)
    return (
        <Table>
            <thead>
                <tr>
                    <TH colSpan='100%'>{name}</TH>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    )
}