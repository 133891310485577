import React from "react";
import styled from "styled-components";

import { I_CANT_BELIEVE_ITS_NOT_BLACK } from '../../Constants/colors'

const Title = styled.h2`
    margin: 30px auto;
    font-size: 4rem;
    color: ${I_CANT_BELIEVE_ITS_NOT_BLACK};
    font-weight: bold;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 3rem;
        margin-bottom: 20px;
    }
    @media (max-width: 450px) {
        font-size: 2rem;
        margin-bottom: 10px;
    }
`

export default function PageTitle({ children, style=null }) {
    return <Title style={style}>{children}</Title>
}