import React from "react";
import styled from "styled-components";

import button from '../../Assets/Button.png';

const Button = styled.button`
    background-color: transparent;
    width: 20%;
    border: none;
    position: relative;
    cursor: pointer;
    &:focus {
        filter: brightness(0.7);
        outline: none;
    }
    &:hover {
        filter: brightness(0.4);
    }
    &:disabled {
        filter: opacity(0.5);
        cursor: auto;
    }
`

const Background = styled.img`
    position: relative;
    width: 100%;
`
const Image = styled.img`
    position: absolute;
    width: 40%;
    top: 13%;
    right: 28%;
    @media (max-width: 500px) {
        width: 30%;
        top: 15%;
        right: 32%;
    }
`

export default function ControlButton({image, name, disabled, onClick}) {
    return (
        <Button 
            disabled={disabled}
            onClick={onClick}
            aria-label={name}
            title={name}>
                <Background src={button}/>
                <Image src={image}/>
        </Button>
    )
}