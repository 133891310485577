import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { BLUE_STEEL, I_CANT_BELIEVE_ITS_NOT_BLACK, POCKET_FULL_OF_SUNSHINE } from "../../Constants/colors";

const Container = styled.div`
    width: 100%;
    display: inline-block;
    margin-bottom: 50px;
    color: ${I_CANT_BELIEVE_ITS_NOT_BLACK};
    & a {
        font-size: 1.5em;
        font-weight: bold;
        color: ${I_CANT_BELIEVE_ITS_NOT_BLACK};
        font-family: monospace;
        text-transform: uppercase;
        color: ${BLUE_STEEL};
        &:hover{
            color: ${POCKET_FULL_OF_SUNSHINE};
            margin-left: 10px;
            text-shadow: 2px 2px black;
        }
    }
`

const Image = styled.img`
    float: left;
    width: 15%;
    height: 15%;
    margin-right: 10px;
`

const Title = styled.h3`
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 5px;
`

const Text = styled.p`
    font-size: 1.5em;
    margin-bottom: 5px;
`

export default function Section({ section, children }) {
    return (
        <Container>
            <Image src={section.image} alt={section.alt}/>
            <Title>{section.title}</Title>
            <Text>{section.text}</Text>
            {children}
            <Link to={section.linkTo}>{section.linkText}</Link>
        </Container>)
}