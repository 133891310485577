const defaultSheetId = '1UoLWwojAisjzRKC8RV4Fdr4x_lVpIM_sXFGf3_FfSew';

export function getUrl(sheetName, sheetId = defaultSheetId) {
    return `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?&sheet=${sheetName}&tq=`
}

export function extractRows(data) {
    const json = JSON.parse(data.substring(47).slice(0, -2));
    return json.table.rows.map(jsonRow => {
        return jsonRow.c.map(jsonItem => jsonItem ? jsonItem.v : null)
    })
}

export function extractDate(dateString) {
    const justTheDate = dateString.split('(')[1].split(')')[0];
    const splitDate = justTheDate.split(',');
    const year = parseInt(splitDate[0]);
    const month = parseInt(splitDate[1]);
    const day = parseInt(splitDate[2]);
    return `${month + 1}/${day}/${year}`
}