import React from "react";
import styled from "styled-components";

const ListItem = styled.li`
    padding: 5px;
`

const Date = styled.span`
    float: right;
    font-size: 0.7rem;
`

export default function ComicListItem({ comic }) {
    
    return (<>
        <ListItem>
            <a href={`/counterbury-tales?strip=${comic.id}`}>
                {comic.id + 1}: {comic.title} <Date>({comic.date})</Date>
            </a>
        </ListItem>
    </>)
}