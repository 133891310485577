import React from "react";
import SectionList from "../Components/Home/SectionList";

import Splash from '../Components/Home/Splash'

export default function Home() {
    return (
        <>
            <Splash />
            <SectionList />
        </>
    )
}