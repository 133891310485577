import React from "react";
import styled from "styled-components";
import { formatJsDateToNormalDate, getRandomInt } from "../../Utils/utils";
import ControlButton from "./ControlButton";

import next from '../../Assets/Next.png'
import first from '../../Assets/First.png'
import prev from '../../Assets/Previous.png'
import last from '../../Assets/Latest.png'
import rand from '../../Assets/Random.png'

const Container = styled.div`
    margin: 10px;
`

const Center = styled.div`
    width: 60%;
    margin: auto;
`

const Info = styled.p`
    text-align: center;
    margin-top: 5px;
`

export default function ComicControls({ current, max, dateString, onUpdate }) {
    const invalid = isNaN(current) || current < 0 || current > max;

    const info = max >= 0 ? (
        <Info>#{current + 1}/{max + 1} - {dateString}</Info>
    ) : (
        <Info>#0/0</Info>
    )

    return (
        <Container>
            <Center>
                <ControlButton image={first} name='First Comic' disabled={current == 0} onClick={() => onUpdate(0)} />
                <ControlButton image={prev} name='Previous Comic' disabled={invalid || current <= 0} onClick={() => onUpdate(current - 1)} />
                <ControlButton image={rand} name='Random Comic' disabled={false} onClick={() => {
                    let rand = getRandomInt(0, max);
                    if (rand >= current) rand++;
                    onUpdate(rand)
                }} />
                <ControlButton image={next} name='Next Comic' disabled={invalid || current >= max} onClick={() => onUpdate(current + 1)} />
                <ControlButton image={last} name='Latest Comic' disabled={current == max} onClick={() => onUpdate(max)} />
            </Center>
            {info}
        </Container>)
}