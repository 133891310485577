import React from 'react';
import styled from 'styled-components';

import { BLUE_STEEL } from '../../Constants/colors';

const CaptionSpan = styled.span`
    font-size: 2em;
    text-align: center;
    color: ${BLUE_STEEL};
    width: 100%;
    padding: 10px;
`

export default function Caption({children}) {
    return <CaptionSpan>{children}</CaptionSpan>
}