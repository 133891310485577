import React from "react";
import styled from "styled-components";
import { BLUE_STEEL, POCKET_FULL_OF_SUNSHINE } from "../../../Constants/colors";

const But = styled.button`
    font-size: 1rem;
    border: 1px solid ${BLUE_STEEL};
    padding: 8px;
    background-color: white;
    border-radius: 4px;
    &:hover {
        border-color: ${POCKET_FULL_OF_SUNSHINE};
        cursor: pointer;
        font-weight: bold;
        box-shadow: 1px 1px 5px ${BLUE_STEEL};
    }
    &:active {
        border-color: ${POCKET_FULL_OF_SUNSHINE};
        background-color: ${POCKET_FULL_OF_SUNSHINE};
        color: white;
        cursor: pointer;
        font-weight: bold;
        box-shadow: 1px 1px 5px ${BLUE_STEEL};
    }
`

export default function Button({children, onClick}) {
    return <But onClick={onClick}>{children}</But>
}